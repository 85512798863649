import React from 'react' 
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledUnderline = styled.div`
  width: 50px;
  height: 4px;
  background: #2c5282;
  margin-top: 80px;
  margin-bottom: 22px;
`

const PageCommunitySection = () => (  
  <StaticQuery 
    query={query}
    render={
      data => {
        const imageDataOne = data.imageOne.childImageSharp.fluid
        const imageDataTwo = data.imageTwo.childImageSharp.fluid
        const imageDataThree = data.imageThree.childImageSharp.fluid

        return (
          <div className='bg-gray-200'>
            <div className='lg:w-10/12 mx-auto py-20 sm:py-6 sm:px-4 md:py-12 md:px-8'>
        
              <h2 className='font-display text-gray-900 text-3xl sm:text-2xl leading-snug mb-6'>Interpacific Asset Management currently manages a wide variety of community associations, from very small condominium complexes with only a few units, to large highrises and planned communities. We strongly believe in providing only the most efficient and professional service possible to each and every association and its members. Our utmost goal is to provide excellent service to all of the associations we manage.</h2>

              <div className='lg:flex block'>
                <div className='lg:w-1/2 w-full pr-8 sm:pr-0'>
                  <p className='text-xl text-gray-900 mb-6'>Interpacific is a member of the Institute of Real Estate Management (IREM), which is a division of the National Association of Realtors through which our property managers gained their designation “Certified Property Manager” (CPM). Interpacific is very proud to be an Accredited Management Organization (AMO), which means that it has met the highest standards of integrity, experience, education, accounting principles and insurance requirements set forth by IREM. Worldwide, there are only about 700 firms that have met these requirements. This makes selecting an AMO in your best interest for assurance that professionals with integrity and experience will manage your association.</p>

                  <p className='text-xl text-gray-900 mb-6 pr-8 sm:pr-0'>Interpacific Asset Management is a Management Firm Council Member of the California Association of Community Managers (CACM), through which we help to keep your association abreast of the latest changes in state legislation and precedent-setting decisions in common interest development lawsuits. Many of our managers have earned the Certified Community Association Manager (CCAM) certification that is given by the California Association of Community Managers, or are in the process of obtaining this prestigious certification. Our staff also includes a full accounting department and customer service support staff necessary to give you the best possible service.</p>
                </div>

                <div className='flex flex-col items-center w-1/2 sm:w-full p-6 sm:p-0'>
                  <Img fluid={imageDataOne} alt='IREM logo' className='w-full mb-12' />
                  <Img fluid={imageDataTwo} alt='CACM logo' className='w-full mb-12' />
                  <Img fluid={imageDataThree} alt='AMO logo' className='w-full' />
                </div>
              </div>
        
              <StyledUnderline></StyledUnderline>
        
              <h3 className='font-display text-gray-900 text-2xl sm:text-xl leading-snug mb-8'>Our Management Program Includes:</h3>
        
              <ul className='flex flex-wrap justify-between p-4'>
                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Board Member and Homeowner Communication.</span> Interpacific provides timely and educated responses to all board member and homeowner inquiries.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Board Meeting Attendance.</span> We attend board meetings, supervise annual elections and prepare meeting minutes and board packages.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Financial Management.</span> Includes homeowner invoicing and monthly financial statements. In addition, we aid the association with the preparation of the annual budget, reserve studies, and the levying of special assessments. Interpacific prides itself in accurate, timely and understandable financial reports.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Insurance.</span> Interpacific will assist the association in acquiring required insurance coverage, including property, liability, directors and officers, workers compensation and fidelity bond.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Repair and Maintenance.</span> We provide quick and effective resolution to service requests and have trained personnel to respond to and follow up on all requests. We also provide monthly summary reports on all work orders.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Administration and Record-Keeping.</span> Interpacific organizes your documents into an HOA-specific filing system and retains all records for the association.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Calendar of Events.</span> We prepare a calendar with due dates for the annual mailings, budget, insurance renewal, reserve study, annual audit and any required dissemination to the members. This aids the association in keeping in compliance with Civil Code requirements.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>Liaison to Legal Representation.</span> Interpacific will interface with the associations’ attorney and assist the association in assessment collection cases, lawsuits involving violations of the governing documents, obtaining legal opinions, and construction litigation.
                  </div>
                </li>

                <li className='w-1/2 sm:w-full flex flex-col items-start text-gray-800 text-xl mb-8 pr-8'>
                  <svg className='fill-current text-blue-900 inline-block h-8 w-8 mb-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM6.7 9.29L9 11.6l4.3-4.3 1.4 1.42L9 14.4l-3.7-3.7 1.4-1.42z"/></svg>
                  <div>
                    <span className='font-bold'>California Civil Code Requirements.</span> We help to ensure that all legal requirements are met. This involves training and educating our managers in the ever-changing laws affecting California associations.
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )
      }
    }
  />      
)

export default PageCommunitySection

const query = graphql`
  query PageCommunitySectionQuery {
    imageOne: file(relativePath: { eq: "logos/irem.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageTwo: file(relativePath: { eq: "logos/cacm-colored.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageThree: file(relativePath: { eq: "logos/amo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`