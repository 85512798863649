import React from "react"
import { graphql } from 'gatsby'
import PageContainer from "../containers/PageContainer"
import HeadData from "../data/HeadData"
import PageHeaderImageBg from '../headers/PageHeaderImageBg'
import PageHeaderText from "../headers/PageHeaderText"
import HeaderNav from "../navigation/HeaderNav"
import PageCommunitySection from "../sections/PageCommunitySection"
import HomeAffiliationSection from "../sections/HomeAffiliationSection"
import CTASection from "../sections/CTASection"

export default ({ data }) => (
  <PageContainer>
    <HeadData 
      title='Community Associations' 
      description='Interpacific Asset Management currently manages a wide variety of community associations, from very small condominium complexes with only a few units, to large highrises and planned communities.'
    />

    <PageHeaderImageBg imageData={data.imageBg.childImageSharp.fluid}>
      <HeaderNav />
      <PageHeaderText
        title='Community Associations'
      />
    </PageHeaderImageBg>

    <PageCommunitySection />

    <HomeAffiliationSection />

    <CTASection />
  </PageContainer>
)

export const query = graphql`
  query CommunityAssociationsPageQuery {
    imageBg: file(relativePath: { eq: "services/home-service-residential-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
